import React, { useContext, useEffect, useMemo, useState } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout/layout";
import BreadCrumb from "../components/layout/breadcrumb";
import { parseStory } from "../utils";
import arrSortProd from "../resources/images/arr-sort-products.svg";
import useWindowSize from "../lib/use-window-size";
import FilterBlok from "../components/storyblok-components/filter-blok";
import filtri from "../resources/filtri";
import CTA from "../components/cta";
import ProductCard from "../components/storyblok-components/cards/product-card";
import pagLeft from "../resources/images/pag-left.svg";
import pagRight from "../resources/images/pag-right.svg";
import { LanguageContext } from "../context/language-context";
import { slugs2name, slugs2url } from "../resources/categorie";
import { getMaterials } from "../components/storyblok-components/cards/utils";
import t from "../resources/translations";
import Seo from "../components/layout/seo";
import { cat2desc, cat2title } from "../resources/seo-categorie";

/* get number from possible string */
const getNumber = (n) => {
  if (typeof n === "number") {
    return n;
  }

  if (typeof n === "string") {
    return parseFloat(n);
  }

  return -1;
};

/* init filters object based on category */
const getInitVals = (cat, location) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let initVals = {};
  if (cat) {
    initVals = Object.entries(filtri[cat] ?? {}).reduce((obj, [name, opt]) => {
      let value = null;
      switch (opt.type) {
        case "range":
          value = [opt.values[0], opt.values[1]];
          if (name in params) {
            const range = params[name]
              .split(",")
              .map((n) => parseFloat(n))
              .filter((n, id) => id < 2 && !isNaN(n));
            if (
              range.length === 2 &&
              range[0] <= range[1] &&
              range[0] >= opt.values[0] &&
              range[1] <= opt.values[1]
            ) {
              value = range;
            }
          }
          break;
        case "multi":
          value = new Set();
          if (name in params) {
            const searchMulti = new Set(params[name].split(","));
            for (let v of opt.values) {
              if (searchMulti.has(v)) value.add(v);
            }
          }
          break;
      }

      obj[name] = { type: opt.type, value: value };
      return obj;
    }, {});

    return initVals;
  }
};

const getPagination = (location, maxCards) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let page = 1;
  if ("page" in params) {
    page = parseInt(params.page);
    if (isNaN(page) || page <= 0 || page > Math.ceil(maxCards / 6)) {
      page = 1;
    }
  }
  return page - 1;
};

const Category = ({ data, location }) => {
  const l = useContext(LanguageContext).locale;
  const { isMobile } = useWindowSize();
  const allCards = useMemo(
    () => data.allStoryblokEntry.nodes.map(parseStory),
    [data]
  );

  /* console.log(
    [
      ...allCards
        .flatMap((c) =>
          c.content.varianti
            .filter((v) => v?.a_macchina)
            .map((v) => v?.a_macchina)
        )
        .reduce((o, n) => {
          o.add(n);
          return o;
        }, new Set()),
    ].sort((a, b) => {
      const aa = a.split("x").map((v) => parseFloat(v.replace(",", ".")));
      const bb = b.split("x").map((v) => parseFloat(v.replace(",", ".")));
      const d = aa.length - bb.length;
      if (d !== 0) {
        return d;
      }
      const cc = aa.map((ae, i) => ae - bb[i]);
      for (const c of cc) {
        if (c !== 0) return c;
      }
      return 0;
    })
  ); */

  const [activeCards, setActiveCards] = useState(allCards);

  const [filtriMobileOpen, setFiltriMobileOpen] = useState(false);
  const [cat] = useState(
    Object.entries(slugs2url).filter(([c, urls]) =>
      Object.values(urls).some((url) => location.pathname.startsWith(url))
    )[0][0]
  );
  const [filters, setFilters] = useState(getInitVals(cat, location));
  useEffect(() => {
    setFilters(getInitVals(cat, location));
  }, [cat]);
  const [pagination, setPagination] = useState(
    getPagination(location, allCards.length)
  );
  const firstCard = (pagination ?? 0) * 6;

  useEffect(() => {
    let results = allCards;
    Object.entries(filters).forEach(([name, f]) => {
      if (name === "materiali") {
        results = results.filter(
          (p) =>
            f.value.size === 0 ||
            getMaterials(p.content).some((m) => f.value.has(m))
        );
      } else if (name === "categoria") {
        results = results.filter(
          (p) => f.value.size === 0 || f.value.has(p.content?.cat ?? "")
        );
      } else if (f.type === "range") {
        results = results.filter((p) =>
          (p.content?.varianti ?? []).some((v) =>
            name in v
              ? getNumber(v[name]) >= f.value[0] &&
                getNumber(v[name]) <= f.value[1]
              : f.value[0] === filtri[cat][name].values[0] &&
                f.value[1] === filtri[cat][name].values[1]
          )
        );
      } else if (f.type === "multi") {
        results = results.filter(
          (p) =>
            f.value.size === 0 ||
            (p?.content?.varianti ?? []).some((v) =>
              f.value.has(
                typeof v[name] === "string" ? v[name].trim() : v[name]
              )
            )
        );
      }
    });
    setActiveCards(results);
  }, [filters, allCards]);

  const [filtriAttivi, setFiltriAttivi] = useState(filtri[cat]);

  useEffect(() => {
    const multiFields = Object.entries(filtri[cat])
      .filter(
        ([k, v]) => v.type === "multi" && k !== "categoria" && k !== "materiali"
      )
      .map(([k, _]) => k);
    const fieldsSet = allCards.reduce(
      (obj, c) => {
        multiFields.forEach((f) => {
          c?.content[f] && obj[f].add(c.content[f]);
          (c?.content?.varianti ?? []).forEach((v) => {
            f in v && obj[f].add(v[f]);
          });
        });
        return obj;
      },
      multiFields.reduce((o, f) => {
        o[f] = new Set();
        return o;
      }, {})
    );
    fieldsSet.materiali = allCards.reduce((o, c) => {
      const mats = (c?.content?.materiali ?? [{}])[0]?.materiale;
      mats && mats.forEach((m) => o.add(m));
      return o;
    }, new Set());

    const newFiltri = Object.entries(filtri[cat]).reduce((obj, [k, v]) => {
      obj[k] = { ...v };
      if (v.type === "multi" && k !== "categoria") {
        obj[k].values = v.values.filter((val) => fieldsSet[k].has(val));
      }
      return obj;
    }, {});
    console.log({ multiFields, fieldsSet, newFiltri });
    setFiltriAttivi(newFiltri);
  }, [allCards, cat]);

  const numberOfActiveFilters = useMemo(
    () =>
      cat && filters
        ? Object.entries(filters).reduce((num, [name, opt]) => {
            switch (opt.type) {
              case "multi":
                return opt.value.size > 0 ? num + 1 : num;
              case "range":
                return filtri[cat][name].values[0] !== opt.value[0] ||
                  filtri[cat][name].values[1] !== opt.value[1]
                  ? num + 1
                  : num;
              default:
                return num;
            }
          }, 0)
        : 0,
    [filters, cat]
  );

  const scrollTop = isMobile ? 50 : 150;

  return (
    <Layout location={location}>
      <Seo title={cat2title[cat][l]} desc={cat2desc[cat][l]} />
      <BreadCrumb>
        <Link to="..">{t.home[l]}</Link>
        <p className="font-bold">{slugs2name[cat][l]}</p>
      </BreadCrumb>
      <div className="relative">
        <div className="mb-11 md:mb-16 text-white is-comm-flex w-full flex-wrap md:flex-nowrap gap-4">
          <h1 className="is-h1m md:is-h1  w-full md:w-auto">
            {slugs2name[cat][l]}
          </h1>
          <button
            className="md:hidden is-comm-flex py-1.5 px-2.5 bg-is-dark-gray rounded-small gap-4"
            onClick={() => {
              setFiltriMobileOpen(true);
            }}
          >
            {t.filtri[l]}
            <img src={arrSortProd} alt="" className="transform -rotate-90" />
            <div className="w-7 h-7 flex items-center justify-center rounded-full bg-is-blue">
              {numberOfActiveFilters}
            </div>
          </button>
        </div>
        <div className="flex items-start">
          {/* Filtri */}
          {cat !== "" && (
            <FilterBlok
              filters={filters}
              setFilters={setFilters}
              options={filtriAttivi ?? {}}
              filtriMobileOpen={filtriMobileOpen}
              setFiltriMobileOpen={setFiltriMobileOpen}
              setPagination={setPagination}
            />
          )}
          <div className="flex-auto md:pl-12">
            {activeCards.length === 0 && (
              <div className="text-center pt-4 md:pt-20 font-bold">
                {t.noProdotti[l]}
              </div>
            )}
            {/* Cards */}
            <div className=" sm:flex sm:gap-12 flex-wrap">
              {activeCards.slice(firstCard, firstCard + 6).map((ac) => (
                <ProductCard key={ac.name} story={ac} />
              ))}
            </div>
            {/* Pagination */}
            {activeCards.length > 0 && (
              <div className="flex items-center justify-center w-full gap-4 mt-12">
                <button
                  disabled={pagination === 0}
                  onClick={() => {
                    setPagination((p) => p - 1);
                    window.scrollTo({ top: scrollTop, behavior: "smooth" });
                  }}
                  className="w-6 h-6 flex justify-center items-center bg-white shadow-is rounded-full"
                >
                  <img src={pagLeft} alt="pagina precedente" />
                </button>
                {Array(Math.ceil(activeCards.length / 6))
                  .fill(0)
                  .map((_, i) => (
                    <button
                      key={i}
                      className="text-white disabled:text-is-blue"
                      disabled={pagination === i}
                      onClick={() => {
                        setPagination(i);
                        window.scrollTo({ top: scrollTop, behavior: "smooth" });
                      }}
                    >
                      {i + 1}
                    </button>
                  ))}
                <button
                  disabled={pagination === Math.ceil(activeCards.length / 6)}
                  onClick={() => {
                    setPagination((p) => p + 1);
                    window.scrollTo({ top: scrollTop, behavior: "smooth" });
                  }}
                  className="w-6 h-6 flex justify-center items-center bg-white shadow-is rounded-full"
                >
                  <img src={pagRight} alt="pagina successiva" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <CTA />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String!, $cat: String!) {
    allStoryblokEntry(
      filter: { field_component: { eq: $cat }, lang: { eq: $lang } }
    ) {
      nodes {
        name
        slug
        content
        field_component
      }
    }
  }
`;

export default Category;
